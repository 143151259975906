var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-string-list"},[_c('chips-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'values',
        'validation': 'required'
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }