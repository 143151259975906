







import BaseStringList from './components/BaseStringList.vue'
import InvertCondition from './components/InvertCondition.vue'
import { EqualsCondition } from '../../../../includes/types/PmConfig.types'

import { Component, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { BaseStringList, InvertCondition }
})
export default class Equals extends Vue {
  @VModel() model!: EqualsCondition

}
