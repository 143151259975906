














import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class InvertCondition extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ type: Boolean, required: true }) invert!: boolean
}
