















import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class BaseStringList extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @VModel({ type: Array, required: true, default: () => [] }) values!: Array<string>
}
